
import { defineComponent, ref } from 'vue';
import VueJsonPretty from 'vue-json-pretty';
import { stringifyPubkeysAndBNsInObject } from '@/common/helpers/util';
import useModal from '@/composables/modal';
import ModalWindow from '@/components/ModalWindow.vue';
import ContentTooltipJSON from '@/components/content/tooltip/ContentTooltipJSON.vue';
import QuestionMark from '@/components/QuestionMark.vue';
import useCopy from '@/composables/copy';
import ContentTooltipRarity from '@/components/content/tooltip/ContentTooltipRarity.vue';

export default defineComponent({
    props: {
        n: Object,
    },
    components: {
        ContentTooltipRarity,
        QuestionMark,
        ContentTooltipJSON,
        ModalWindow,
        VueJsonPretty,
    },
    setup() {
        const isMaster = (editionType: string) => editionType.toLowerCase().includes('master');
        const fullJSON = ref(false);
        const NOT_FOUND = 'Not found';
        const toggleJSON = () => {
            fullJSON.value = !fullJSON.value;
        };
        // --------------------------------------- clipboard
        const { copyText, doCopyJSON } = useCopy();
        // --------------------------------------- modal
        const { registerModal, isModalVisible, showModal, hideModal } = useModal();
        registerModal('tooltipJSON');
        registerModal('tooltipRarity');
        return {
            isMaster,
            fullJSON,
            toggleJSON,
            stringifyPubkeysAndBNsInObject,
            // clipboard
            copyText,
            doCopyJSON,
            // modal
            isModalVisible,
            showModal,
            hideModal,
            NOT_FOUND,
        };
    },
});
